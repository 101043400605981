

































































































































import Component, { mixins } from "vue-class-component";
import { Navigation } from "@/mixins/navigation";
import { Prop } from "vue-property-decorator";
import { Notification } from "@/models/notification.interface";
import { UserValidations } from "@/mixins/user-validations";
import { StyleCheck } from "@/mixins/style-check";

@Component({})
export default class CommentActions extends mixins(
  Navigation,
  UserValidations,
  StyleCheck
) {
  @Prop() comment!: any;

  loading = false;
  dialogRemove = false;
  dialogResolve = false;

  private async resolveComment() {
    await this.updateProductCommentStatus(this.$constants.STATUS.FINISHED);
    this.dialogResolve = false;
  }

  private async removeComment() {
    await this.updateProductCommentStatus(this.$constants.STATUS.DELETED);
    this.dialogRemove = false;
  }

  private async updateProductCommentStatus(status: string) {
    this.loading = true;
    const comments: any[] = [];
    this.comment.comments.forEach((comment: any) => {
      comments.push(comment.id);
    });
    comments.push(this.comment.id);
    await this.$store
      .dispatch("comments/updateProductCommentStatus", {
        status: status,
        comments: comments,
      })
      .then(async () => {
        const Success: Notification = {
          message: this.$tc("CommonLayout.success"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: false,
        };

        this.$store.dispatch("notifications/showNotification", Success);
        this.$emit("updateComments");
      })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("CommonLayout.error"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  private get context(): string {
    return this.$store.getters["authentication/getContext"];
  }
}
